import React from 'react';
import { FaAward } from 'react-icons/fa';
import {  VscFolderLibrary } from 'react-icons/vsc';
import { BiBookReader } from 'react-icons/bi';

import './about.css';
import ME from '../../assets/ME_about.png';

function About() {
  return (
    <section id='about'>
      <h5>Want To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="ME" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>Developed a few working apps</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>12 completed, 7 ongoing</small>
            </article>

            <article className="about__card">
              <BiBookReader className='about__icon'/>
              <h5>Knowledge</h5>
              <small>Multi programming language and frameworks</small>
            </article>
          </div>

          <p>
            Even though I'm still a high school boy, I have a lot of experience because I learned coding by myself by ebooks and documentation. That's what makes me learn coding faster despite the different programming languages and how to use each framework. And I'm a security researcher too.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Chat</a>
        </div>
      </div>
    </section>
  )
}

export default About