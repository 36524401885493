import React from 'react';
import { BsGithub, BsInstagram, BsTwitter, BsFacebook } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';

function HeaderSocials() {
    return (
        <div className='header__socials'>
            <a href="https://instagram.com/ahazriq7872">
                <BsInstagram />
            </a>
            <a href="https://twitter.com/ahazriq7872">
                <BsTwitter />
            </a>
            <a href="https://github.com/PishangShedappp">
                <BsGithub />
            </a>
            <a href="https://facebook.com/PishangShedappp">
                <BsFacebook />
            </a>
            <a href="mailto:akmalhazriq@gmail.com">
                <HiOutlineMail />
            </a>
        </div>
    )
}

export default HeaderSocials