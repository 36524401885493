import React from 'react'

function CTA() {
    return (
        <div className='cta'>
            <a href='#experience' className='btn'>See works</a>
            <a href='#contact' className='btn btn-primary'>Let's Chat</a>
        </div>
    )
}

export default CTA