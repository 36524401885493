import React, {useRef, useState} from 'react';
import emailjs from 'emailjs-com';
import {MdOutlineMail} from 'react-icons/md';
import {BsInstagram} from 'react-icons/bs';
import {RiMessengerLine} from 'react-icons/ri';

import './contact.css';
import { toast } from 'react-hot-toast';

function Contact() {
  const [name, setName] = useState('');

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_xur8xnm', 'template_2c7x22p', form.current, 'K8cN04OKDmxnIF2N2');

    toast.success(`Thank you ${name} for getting in touch!`, {
      style: {
        borderRadius: '10px',
        fontSize: '700'
      }
    })

    e.target.reset();
  }

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact__container'>
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>akmalhazriq@gmail.com</h5>
            <a href='mailto:akmalhazriq@gmail.com'>Send Email</a>
          </article>
          <article className='contact__option'>
            <BsInstagram className='contact__option-icon'/>
            <h4>Instagram</h4>
            <h5>ahazriq7872</h5>
            <a href='https://instagram.com/ahazriq7872'>Send Message</a>
          </article>
          <article className='contact__option'>
            <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>Akmal Hazriq</h5>
            <a href='https://m.me/PishangShedappp'>Send Messages</a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder='Your Full Name' onChange={e=>setName(e.target.value)} required/>
          <input type='email' name='email' placeholder='Your Email' required/>
          <textarea name='messages' rows='7' placeholder='Your Message'></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact