import React from 'react';
import Typical from 'react-typical';

import CTA from './CTA';
import HeaderSocials from './HeaderSocials';
import './header.css';
import me from '../../assets/me.png';

function Header() {
    return (
        <header>
            <div className='container header__container'>
                <h5>Hello I'm</h5>
                <h1>Akmal Hazriq</h1>
                <h5 className='text-light'>
                    <Typical 
                        loop={Infinity}
                        wrapper='b'
                        steps={[
                            'DEVELOPER 🖥️',
                            1000,
                            'SECURITY RESEARCHER 👨🏻‍💻🕵🏻‍♂️',
                            1000,
                            'Desktop Application Developer',
                            1000,
                            'Mobile Application Developer',
                            1000,
                            'Web Application Developer',
                            1000,
                            'Fullstack Developer',
                            1000
                        ]}
                    />
                </h5>
                <CTA />
                <HeaderSocials />
                <div className='me'>
                    <img src={me} alt='me'/>
                </div>
                <a href='#contact' className='scroll__down'>
                    Scroll Down
                </a>
            </div>
        </header>
    )
}

export default Header