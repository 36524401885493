import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Contact from './components/contact/Contact'
import Experience from './components/experience/Experience'
import Services from './components/services/Services'
import { Toaster } from 'react-hot-toast'

function App() {
  return (
    <>
      <Header />
      <Nav />
      <About />
      <Experience />
      {/*<Services />*/}
      <Contact />
      <Toaster 
        position='top-center'
        reverseOrder={false}
      />
    </>
  )
}

export default App